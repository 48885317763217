import React from "react";

const Celection = () => {

    return(
        <>
         <section className="hero-section" id="section_1">
          <div className="section-overlay" />
          <div className="video-wrap">
            <img src="assets/images/Society-Election-1.jpg" alt="" className="custom-video" id="imgym" />
          </div>
        </section>
        <section className="about-section">
          <div className="container">
            <h2 className="text-danger mt-2">Election Procedure for Housing Societies</h2>
            <hr />
          </div>
        </section>
        <section>
          <div className="container">
            <p className="nmn" style={{textAlign: 'justify', fontWeight: 500}}>Co-operative housing societies follow the model
              bye-laws, which are comprehensive and prescribe a number of rules and procedures for elections.
              In this article, we will simplify and decode how election of managing committee of housing society
              works. We will cover the important facets that truly
              matter for the election procedure for a co-operative society.</p> <br />
            <hr style={{size: 2, color: 'black'}} className="my-5 mx-5" />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">Housing
              society election due date</h6>
            <p style={{textAlign: 'justify', fontWeight: 500}}>Within the first three months of registration
              of the society, the Chief
              Promoter is required to call the First General Body Meeting of the society, Election being one of
              the topmost priorities of the
              meeting along with the constitution of the Provisional Committee until regular elections are held
              under bye-laws of the Society.
              Regular elections are supposed to be held once every five years as
              per the Bye-Laws.</p><br />
            <hr style={{size: 2, color: 'black'}} className="my-5 mx-5" />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">Voting
              rights of members of housing society</h6>
            <p style={{textAlign: 'justify', fontWeight: 500}}><strong>All members:</strong> According to a
              recent amendment, any eligible or associate member is to be granted the right to
              vote as soon as he/she is admitted as a member of the society..</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}><strong>On house, one vote:</strong>Each unit
              of
              society has a vote. For example, if you own multiple flats/houses in a society, each of them is
              liable to get a vote.
              Even those who default payments
              are allowed to vote.</p><br />
            <hr style={{size: 2, color: 'black'}} className="my-5 mx-5" />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">Who is not
              eligible for candidature?who cannot become committee member of housing society?</h6>
            <p style={{textAlign: 'justify', fontWeight: 500}}>According to the Model Bye-Laws, a critical
              reason for a member to
              not be able to contest an election is If he defaults the payment of dues to the society, within
              three months from the date of service of
              notice in writing.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>An Associate Member (a person with joint
              ownership whose name does
              not stand first in the Share Certificate) for whom the Original Member has not issued a No-Objection
              Certificate
              cannot contest the Election.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Proposers (someone who nominates a candidate)
              and seconders
              (someone who supports the nomination) have no such restrictions as they are allowed to nominate and
              support any number
              of candidatures irrespective
              of defaulted payments or arrears.</p><br />
            <hr style={{size: 2, color: 'black'}} className="my-5 mx-5" />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">How many
              members are required in a managing committee?</h6>
            <p style={{textAlign: 'justify', fontWeight: 500}}><strong>All members:</strong> Under Bye-Law
              113, the strength of the Managing
              Committee could be made up of 11, 13, 15, 17 or 19 members, with respect to the number of total
              members in the society.
              The break-up is as follows:</p><br />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">Forming a
              managing committee</h6>
            <p style={{textAlign: 'justify', fontWeight: 500}}>Model Bye-Laws from 114 to 116 have put forth
              certain laws that
              help you in forming a strong and reliable
              Managing Committee.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Members of the Committee are to be elected
              every five years
              (before the expiry of the five-year term). An E-2 form to be submitted to the to the District
              Co-operative Election
              Officer or Taluka or Ward Co-operative Election Officer six months prior to the expiry of the
              Committee period of the
              society in case if the society has over 200 members. If they do not notify the State Election
              Authority and continue to
              hold office, it is a violation of the law resulting
              in action from the Registrar.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>In order to oversee the objectives, special
              requirements
              or functions of the Society, two Expert Directors and/or two Functional Directors may be co-opted.
              But they will have
              no right to vote and do not count
              as members of the Managing Committee.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>No office bearer is allowed to have any
              financial, transactional
              or vested interests with regards to the society, unless the financial transactions involve giving
              residential accommodation
              to the paid member of the society.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Societies with less than 200 members
              conducting independent
              elections are liable for a Rs 25,000 fine if they fail to produce accounts, documents, or paperwork
              to
              the government or the members of the society.</p><br />
            <hr style={{size: 2, color: 'black'}} className="my-5 mx-5" />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">Who cannot
              become committee member of housing society?</h6>
            <p style={{textAlign: 'justify', fontWeight: 500}}>Members with questionable moral behaviour,
              defaulted payments within
              three months of written notice, Associate Member without NOC from original member are not eligible.
              Anyone who sublets or rents
              their house without notifying the society in advance is also
              not eligible.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Anyone who fails to furnish valid
              bookkeeping/expenditure records
              with respect to the funds provided by the society is not eligible. Anyone who neglects their
              assigned duties and is deemed
              to be in breach of trust is not eligible to be
              part of the committee.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Over and above that, if a member has failed
              to attend any three
              consecutive monthly meetings of the Committee, without leave of absence, he is disqualified
              from the Committee.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Retired members who have not been
              disqualified are eligible for election.</p><br />
            <hr style={{size: 2, color: 'black'}} className="my-5 mx-5" />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">
              Co-operative society election rules and process</h6>
            <p style={{textAlign: 'justify', fontWeight: 500}}>The housing society election rules requires
              one to
              declare the initial procedures to the voters by the Managing Committee ( or the Provisional Managing
              Committee
              in case if the first Election is to be
              conducted) at least three months prior to the Election date.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>A Returning Officer should be appointed to
              carry out the Voting
              Procedures and ensure adherence of the rules. A Returning Officer should be someone who is not
              contesting the Election in
              any capacity. Nor should he be proposing or seconding a candidate. In other words, he should be an
              absolutely unbiased and
              trusted individual. Usually, someone takes up this position voluntarily. Otherwise, members jointly
              entrust the
              responsibilities on a member they deem fit for the duties. In many cases, a financial compensation
              is made to the
              Returning Officer for his
              professional services.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>The Conduct of Election should involve the
              following steps that
              make the entire procedure methodical and hassle-free. Each process should be published on the Notice
              Board of the society
              within ten days of displaying the final
              list of the voters.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>If the paid up share capital of the said
              society is more than Rs. 10, 000, the voting is conducted by secret ballot.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>At the time of the voting, each member is
              shown an empty ballot box before they cast their vote. The Ballot Paper usually has the seal of the
              society and the counterfoil the initials of the Returning Officer. The Ballot Box is sealed in front
              of the voters and all the documents/votes relevant to the Election are stored safely by the
              Secretary who is required to preserve them for three months and then destroy them afterwards. This
              is done in case of disputes.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>After the results, the current committee
              hands over the charge to the newly elected committee.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>In summary, timely execution of required
              procedures is quite essential to carry out an Election successfully. At each step of the way, the
              members need clarity and transparency from the elected members . At the same time, members need to
              be proactively involved in the election procedures as it is commonly observed in many housing
              societies that the same individuals are selected as office bearers in every term. This may lead to
              lethargy, inefficiency, or malfeasance in many cases.</p><br />
            <hr style={{size: 2, color: 'black'}} className="my-5 mx-5" />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">Important
              points to note regarding housing society elections</h6>
            <p style={{textAlign: 'justify', fontWeight: 500}}>Equal voting right: In general, each member
              of the housing society who owns a property has equal voting rights. In general, a member with a
              larger flat, say 3BHK, does not have a higher voting weightage than someone with a smaller flat, say
              2BHK. A person who owns two flats in the society, on the other hand, gets two votes. Votes are
              usually cast based on the number of units rather than the number of members. In exceptional
              societies formed as limited liability companies rather than cooperatives, voting rights vary
              depending on the size of the unit. The Right2Vote eVoting platform allows you to create all types of
              elections, including those with weightage based on the number of units and weightage based on the
              size of the flat.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Voting rights of owners: All members of the
              housing society who own property have the right to vote. No member should be denied the right to
              vote. In most cases, the first owner has the right to vote, but co-owners may be allowed to vote as
              well.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Denial of voting rights: A bylaw may state
              that members who have not paid their society dues in full may be denied voting rights.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Tenant voting rights: In general, tenants do
              not have voting rights. Each flat’s voting rights are limited to the flat’s owner.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Voting window: To vote, a voter must be given
              a reasonable voting window. Keeping a very short voting window is restrictive and may lead to
              members complaining that their right to vote was denied. We recommend a minimum voting window of 8
              hours if all of your voters are in India, and a minimum of 24 hours if your members are located
              outside of India to account for different time zones. In general, many societies keep a voting
              window of less than 2 hours, which can lead to legal issues.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Reasonable notice of election date and voting
              window: All voters should be given advance notice of the elections. In general, a minimum of 14 days
              advance notice is required. However, depending on the bye law, it can vary from society to society.
            </p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Intimation of election date and process: A
              society must notify its members in advance of its intention to hold elections and solicit
              nominations for various positions. This is usually done at least 21 days before the election date.
              The rules and procedures for nomination and election must also be distributed by the society.
              Right to stand in elections: All owners/members have the same right to vote in elections. No owner
              can be denied the right to vote unless:
              the owner’s title to the property is in dispute.
              The owner has not paid all of the society’s dues.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Election method: The Acts or model bye laws
              have generally been silent on the election method, so both paper-based voting and online voting can
              be used. A show of hands is generally not recommended, especially when a’secret ballot’ is required.
              More information on this topic can be found on this blog..</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Secret ballot: Most State Cooperative Acts
              and model bylaws state that members should be elected by secret ballot. Nobody, not even the
              election officer, should be able to tell which members voted for which candidates using a secret
              ballot. This is to ensure secrecy of member’s vote so that member can vote with his free will and
              there is no chance of coercion.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Proxy voting: The majority of Acts and model
              bylaws state that “members should vote in person.” This means that proxy voting is not permitted. It
              should not be confused with the requirement of voter physical presence. It simply means that a voter
              cannot delegate his or her voting rights and responsibilities to someone else. Proxy voting clauses
              differ from society to society; please carefully review your bylaws for the same..</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Postal voting: Many Acts and bye-laws
              expressly provide for postal voting, in which members can vote by mail. However, due to election
              deadlines, postal voting is generally not feasible, and online voting is a preferred option for
              voters who live elsewhere or are traveling.</p><br />
            <p style={{textAlign: 'justify', fontWeight: 500}}>Election of Committee Members: Rules differ
              in model byelaws relating to election of committee members. In a few states, such as West Bengal,
              members vote to elect committee members, and the committee members decide who will hold the titles
              of President, Treasurer, Secretary, and so on. Members in other states, such as Karnataka, vote
              directly to appoint specific candidates to positions such as President, Treasurer, Secretary, and so
              on. Members in other states, such as Karnataka, vote directly to appoint specific candidates to
              positions such as President, Treasurer, Secretary, and so on. A few members are also elected to the
              committee. Right2Vote’s eVoting platform includes ‘Team Election – Exact’ and ‘Team Election – Upto’
              features to assist societies in electing a team of committee members from the same list of
              candidates. Check your bylaws to see if voters can choose an exact number of candidates or if they
              can choose fewer than the number of positions. Both customizations are available on the Right2Vote
              platform. This feature is not available on the majority of other platforms.</p><br />
          </div>
        </section>
   
    </>
    );
}
export default Celection;
