import React from "react";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
import Csociety from "./Csociety";

const Usociety = () => {

    return(
        <>
            <Unavbar />
           <Csociety />
            <Footer/>
    </>
    );
}
export default Usociety;
