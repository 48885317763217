import React from "react";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
import Claw from "./Claw";

const Ulaw = () => {

    return(
        <>
            <Unavbar />
            <Claw />
            <Footer/>
    </>
    );
}
export default Ulaw;
