
import Footer from "../components/Footer";
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Unavbar from "../components/Unavbar";
import Ucoffer from "../components/Ucoffer";


function Uoffer() {
  const location = useLocation();

  useEffect(() => {
    const scrollToHash = () => {
      const id = location.hash.substr(1); 
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };
    scrollToHash();
    window.addEventListener('hashchange', scrollToHash);
    return () => {
      window.removeEventListener('hashchange', scrollToHash);
    };
  }, [location]);

    return(
      <>
 


 <style dangerouslySetInnerHTML={{ __html: " .navbar {background-color: #babebf;} .hero-section{height: auto;}" }} /> 
    <Unavbar />

    <div className="container hero-section">
    <Ucoffer />
 
        <div className="row"  id="Grocery">
      
      <div className="col-12">
      <div className="row">
         <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Grocery </span></div>
        
         <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
       </div>
      </div>
 
       <div className="col-lg-3 col-6 mt-3">
             <Link to='/dashboard'> 
               <div class="card rounded-4">
                 <img src="assets/images/gorsari/1.jpg" alt="..." class="card-img-top" id="cimg" />
                 <div class="card-body">
                 <div className="row">
                     <div className="col-8">
                     <h2 class="post-title mt-3"> Blinkit </h2>
                     Get upto Rs.50 
                     </div>
                     <div className="col-4">
                     <img src="assets/images/gorsari/blinkit.png" alt="" className="w-100 mt-3" />
                     </div>
                   </div>
                 
                   <a class="card-text mb-3 ">Cashback on Blinkit!</a>
 
                 </div>
               </div>
               </Link> 
       </div>
 
       <div className="col-lg-3 col-6 mt-3">
       <Link to='/dashboard'>  
               <div class="card rounded-4">
                 <img src="assets/images/gorsari/4.jpg" alt="..." class="card-img-top" id="cimg" />
                 <div class="card-body">
                  
                   <div className="row">
                     <div className="col-8">
                     <h2 class="post-title mt-3"> JioMart</h2>
                       Get upto 10% 
                     </div>
                     <div className="col-4">
                     <img src="assets/images/gorsari/jio.png" alt="" className="w-100 mt-3" />
                     </div>
                   </div>
                     <a class="card-text mb-3  mt-0">Cashback on Jiomart!</a>
                 </div>
               </div>
               </Link>
       </div>
 
         <div className="col-lg-3 col-6 mt-3 ">
         <Link to='/dashboard'>
               <div class="card rounded-4">
                 <img src="assets/images/gorsari/2.jpg" alt="..." class="card-img-top" id="cimg" />
                 <div class="card-body">
                 <div className="row">
                     <div className="col-8">
                     <h2 class="post-title mt-3">Swiggy</h2>
                     Get assured Rs.30 
                     </div>
                     <div className="col-4">
                     <img src="assets/images/gorsari/swiggy.png" alt="" className="w-100 mt-3" />
                     </div>
                   </div>
                 
                   <a class="card-text mb-3 ">Cashback on Swiggy Instamart !</a>
 
                 </div>
               </div>
               </Link>
       </div>
 
       <div className="col-lg-3 col-6 mt-3 ">
       <Link to='/dashboard'>
               <div class="card rounded-4">
                 <img src="assets/images/gorsari/3.jpg" alt="..." class="card-img-top" id="cimg" />
                 <div className="card-body">
                   <div className="row">
                     <div className="col-8">
                     <h2 class="post-title mt-3">Zepto</h2>
                     Get Free Zepto Pass
                     </div>
                     <div className="col-4">
                     <img src="assets/images/gorsari/zepto.png" alt="" className="w-100 mt-3" />
                     </div>
                   </div>
                   <a class="card-text mb-3 ">  &  10% Cashback on Zepto on </a> 
                 </div>
               </div>
               </Link>
       </div>
 
     </div>
    
     <div className="row mt-5" id="Food">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Food</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Food/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Zomato </h2>
                      Get upto Rs.100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/zomato.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on Zomato!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Food/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">  Swiggy</h2>
                      Get Flat Rs.30      
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/swiggy.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback on Swiggy! </a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Food/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Domino's</h2>
                      Get upto 10% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/domino.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on Dominos!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Food/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">  EazyDiner</h2>
                      Get upto Rs 500 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/eazydiner.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 "> Cashback on eazydiner! </a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="Gaming">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Gaming</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Gaming/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Dream11 </h2>
                      Get upto Rs.100
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/dream11.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on Dream11! </a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Gaming/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> MyTeam11</h2>
                      Get upto 20% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/myteam11.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback on MyTeam11 !</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Gaming/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Real11</h2>
                 Get upto 20% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/real11.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on Real 11!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Gaming/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Batball 11</h2>
                      Get upto 5% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/batball11.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 "> Cashback on BATBALL11 ! </a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="StoresNearYou">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Stores Near You</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Near/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Nike Store </h2>
                      Get upto Rs 300 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/nike.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 "> cashback at the Nike Factory Store</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Near/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Melorra</h2>
                      Get up to Rs. 1000 Cashback
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/melorra.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0"> at Melorra!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Near/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Being Human </h2>
                      Get up to Rs. 500 Cashback
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/beinghuman.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 "> at Being Human !</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Near/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Reliance SMART</h2>
                      Get Flat Rs 40 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/smart.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 ">Cashback Fresh and Sahakari Bhandar stores! </a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="GroceryStores">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Grocery Stores</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Grocery/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Deerika Hypermart </h2>
                      Get upto Rs 100 Cashback
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/deerika.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 "> at Deerika Hypermart!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Grocery/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Reliance SMART</h2>
                      Get Flat Rs 40 Cashback 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/smart.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">at Reliance SMART, Fresh and Sahakari Bhandar stores!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Grocery/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Modern Bazaar</h2>
                      Get up to Rs.100 Cashback 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/modern.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">at Modern Bazaar !</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Grocery/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Local Shops</h2>
                      Earn 10% Supercash
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/stores.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 ">  @ Local Stores </a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="Shopping">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Shopping</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Lifestyle/s1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Myntra </h2>
                      Get upto 15% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/myntra.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 "> cashback on Myntra!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Lifestyle/s2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Ajio</h2>
                      Get upto 15% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/ajio.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback on Ajio!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Lifestyle/s3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">GOFYND</h2>
                      Get upto 10%
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/gofynd.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 "> Cashback on GOFYND!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Lifestyle/s4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Snitch</h2>
                      Get up to 10% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/snitch.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 ">cashback up to Rs 250 on payment via MobiKwik wallet! </a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="LifestylePayCashback">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Lifestyle Pay Cashback</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Lifestyle/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Pantaloons </h2>
                      Get upto Rs 200
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/pantaloons.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 "> Cashback at Pantaloons</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Lifestyle/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Melorra</h2>
                      Get up to Rs. 1000 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/melorra.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback at Melorra!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Lifestyle/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Combonation</h2>
                      Get upto Rs. 350 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/combonation.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback at Combonation!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Lifestyle/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Reliance Trends</h2>
                      Get upto Rs 200 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/trends.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 ">Cashback at Reliance Trends ! </a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="Travel">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Travel</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Travel/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> EaseMyTrip </h2>
                      Get flat 10% Instant 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/easemytrip.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Discount upto Rs.555 on EaseMyTrip!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Travel/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Cleartrip</h2>
                      Get Flat 555   Instant 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/cleartrip.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">  Discount on Cleartrip!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Travel/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">MakeMyTrip</h2>
                      Get Flat 10% Instant 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/makemytrip.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Discount upto Rs.555 on MakeMyTrip!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Travel/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">goibibo</h2>
                      Get Flat 10% Instant 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/goibibo.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 ">Discount upto Rs.555 on goibibo!</a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="Health">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Health</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/health/h1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">  Tata_1Mg </h2>
                      Get upto Rs.500
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/1mg.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 "> Cashback on Tata 1Mg!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/health/h2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Pharmeasy</h2>
                      Get upto Rs.500 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/pharmeasy.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback on Pharmeasy!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/health/h3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Flipkart Health+</h2>
                      Get Flat Rs.50 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/flipkarthealth.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on Flipkart Health!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/health/h4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Apollo Pharmacy</h2>
                      Get upto Rs.500 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/apollopharmacy.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 ">cashback on Apollo Pharmacy!</a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
      
      <div className="row mt-5" id="ShoppingStores">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Shopping Stores</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Shopping/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Reliance Trends</h2>
                      Get upto Rs 200 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/trends.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback at Reliance Trends!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Shopping/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Jio Stores</h2>
                      Get up to Rs 500 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/stores.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback at Jio Stores!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Shopping/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Croma Stores</h2>
                      Get up to Rs.400
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/Croma.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 "> Cashback at Croma Stores!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Shopping/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Pantaloons</h2>
                      Get upto Rs 200 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/pantaloons.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 ">Cashback at Pantaloons ! </a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="TopBrands">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Top Brands</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Top/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> MakeMyTrip </h2>
                      Get Flat 10% Instant 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/makemytrip.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Discount upto Rs.555 on MakeMyTrip!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Top/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Zomato</h2>
                      Get upto Rs.100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/zomato.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback on Zomato!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Top/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Swiggy</h2>
                      Get assured Rs.30 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/swiggy.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on Swiggy Instamart !</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Top/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Blinkit</h2>
                      Get upto Rs.50 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/blinkit.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 ">Cashback on Blinkit! </a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="Beauty&PersonalCare">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Beauty & Personal Care</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Beauty/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Wildly Pure </h2>
                      Get upto 10%
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/wildlypure.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 "> cashback on Wildly Pure!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Beauty/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Mamaearth</h2>
                      Get upto 10%
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/biovalley.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0"> cashback on Mamaearth !</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Beauty/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">MANCODE</h2>
                      Get upto 5% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/mancode.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">cashback on MANCODE!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Beauty/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Quench</h2>
                      Get upto 15% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/quench.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 "> cashback on Quench!</a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="CashbackNextDoor">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Cashback Next Door</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Door/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> 37 Krishna Marche </h2>
                      Get up to Rs.100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/37-Krishna.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback at 37 Krishna Marche!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Door/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Kondli Bazaar</h2>
                      Get upto Rs. 100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/kondlibazaar.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback at Kondli Bazaar!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Door/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Deerika Hypermart</h2>
                      Get upto Rs 100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/deerika.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback at Deerika Hypermart!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Door/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Yuvaa</h2>
                      Get upto Rs. 50
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/yuvaa.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 "> Cashback at Yuvaa !</a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
      
      <div className="row mt-5" id="Entertainment">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Entertainment</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Entertainment/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> BookMyShow </h2>
                      Get upto 20% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/Bookmyshow.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on BookMyShow!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Entertainment/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Justickets</h2>
                      Get upto 20% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/justickets.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback on Justickets!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Entertainment/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Sonyliv</h2>
                      Get upto Rs.200 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/sonyliv.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on Sonyliv!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Entertainment/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Times Prime</h2>
                      Get upto 20% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/timesprime.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 "> Cashback on Times Prime! </a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="HumaraBrandistan">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Humara Brandistan</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Humara/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Ancel </h2>
                      Get upto 10% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/ancel.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">cashback on Ancel!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Humara/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> fabindia</h2>
                      Get upto Rs.1000 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/fabindia.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback on fabindia!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Humara/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Street Store</h2>
                      Get upto Rs.250 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/street.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">cashback on Street Style Store!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Humara/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">APKAINTERIOR</h2>
                      Get upto 5% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/apkainterior.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 "> cashback on APKAINTERIOR.com! </a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="Cashback">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Cashback</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Cashback/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> lybrate </h2>
                      Get upto Rs.300 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/lybrate.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on lybrate!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Cashback/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Credit card offer</h2>
                      Get flat Rs.100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/creditcard.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback on your first Credit card Bill payment!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Cashback/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Ixigo</h2>
                      Get flat Rs.555
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/ixigo.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 "> instant discount on Ixigo!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Cashback/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Cleartrip</h2>
                      Get Flat 555 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/cleartrip.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 ">Instant Discount on Cleartrip!</a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="Electronicsline">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Electronics line</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Electronics/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Realme </h2>
                      Get upto Rs.1500 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/realme.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on realme!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Electronics/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Samsung</h2>
                      Get Flat 7% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/samsung.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">cashback on your Pre-Reserve!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Electronics/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Oppo</h2>
                      Get upto Rs.1500 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/oppo.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on Oppo!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Electronics/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Motorola</h2>
                      Get upto Rs.1000 
 
 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/motorola.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 ">cashback on via MobiKwik Wallet/ ZIP payment!</a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
      
      <div className="row mt-5" id="Deal&Gifting">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Deal & Gifting</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Deal/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> FernsNPetals </h2>
                      Get upto 15% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/fnp.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on Ferns N Petals!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Deal/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Zoomin</h2>
                      Get upto Rs.250
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/zoomin.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0"> Cashback on Zoomin!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Deal/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">WINNI</h2>
                      Get upto Rs.750 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/winni.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on WINNI!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Deal/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">igp.com</h2>
                      Get upto 10% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/igp.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 "> Cashback on igp.com ! </a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="UPIOffers">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>UPI Offers</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/gorsari/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Dream11 UPI </h2>
                      Get upto Rs.50 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/dream11.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback When Payment via MobiKwik UPI on Dream11!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/gorsari/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> MyTeam11</h2>
                      Get upto Rs.50 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/myteam11.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback on MyTeam11 on payments via Mobikwik UPI Only!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/gorsari/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">My11Circle</h2>
                      Get upto Rs.50
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/my11circle.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 "> Cashback on My11Circle on Payment via MobiKwik UPI only!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/gorsari/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">A23</h2>
                      Get upto Rs.100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/Ace2three.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 ">cashback when payment via Mobikwik UPI on A23!</a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="Recharge&Bills">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Recharge & Bills</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Recharge/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">  Electricity bills </h2>
                      Flat Rs.30 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/bijlibill.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on Electricity bills !</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Recharge/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">  LPG booking</h2>
                      Flat Rs.30 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/jio.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback on LPG booking !</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Recharge/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">DTH Recharge</h2>
                      Get flat Rs.25 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/swiggy.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on first DTH Recharge!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Recharge/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Recharge Offer</h2>
                      Get 1% SuperCash 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/zepto.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 "> up to Rs.200 on recharges</a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="FundTransfer">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Fund Transfer</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/infinite/f1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Century </h2>
                      Get 1% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/century.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">cashback upto Rs.50</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/infinite/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Super75</h2>
                      Get 1% SuperCash 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/super75.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">on Add Money via credit card!</a>
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
      
      <div className="row mt-5" id="FreakyFri-YaY">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}> Freaky Fri-YaY</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/Freaky/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">  Reliance SMART </h2>
                      Get Flat Rs 40 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/smart.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback at, Fresh and Sahakari Bhandar stores!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/Freaky/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Modern Bazaar</h2>
                      Get up to Rs.100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/modern.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback at Modern Bazaar!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Freaky/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Deerika Hypermart</h2>
                      Get upto Rs 100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/deerika.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback at Deerika Hypermart!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/Freaky/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">37 Krishna Marche</h2>
                      Get up to Rs.100
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/37-krishna.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 "> Cashback at 37 Krishna Marche </a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="Essentials">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}> Essentials</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/infinite/e1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> V candle supplies </h2>
                      Get upto 10% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/vcandlesupplies.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">cashback on V candle supplies!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
       
      </div>
 
      <div className="row mt-5" id="InfiniteSavings">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Infinite Savings</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/infinite/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Credit card offer</h2>
                      Get 1% SuperCash 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/creditcard.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">on your every Credit card Bill payment</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/infinite/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">  Super75</h2>
                      Get 1% SuperCash 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/super75.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">on Add Money via credit card</a>
                  </div>
                </div>
                </Link>
        </div>
  
        
  
      </div>
 
      <div className="row mt-5" id="Education">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Education</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/edu/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> ELOIT </h2>
                      Get Flat Rs.100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/eloit.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on Edisapp Parent App !</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/edu/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> BuyTestSeries</h2>
                      Get Cashback upto Rs.500
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/buytestseries.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0"> Cashback on BuyTestSeries!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/edu/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Bookish Santa</h2>
                      Get upto Rs.75 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/bookishsanta.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on Bookish Santa!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/edu/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">elearnmarkets</h2>
                      Get upto 10% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/elearnmarkets.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 ">cashback on elearnmarkets!</a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
      
      <div className="row mt-5" id="ZIPOffers">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>ZIP Offers</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/infinite/z1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> SuperCash </h2>
                      5% SuperCash via Zip
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/supercash.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">via Zip !</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
      </div>
 
      <div className="row mt-5" id="HealthStores">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Health Stores</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/health/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Apollo Pharmacy </h2>
                      Get Flat Rs 50 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/apollopharmacy.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback at Apollo Pharmacy!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/health/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Sabka Dentist</h2>
                      Get Flat Rs.100
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/sabkadentist.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0"> Cashback at Sabka Dentist!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/health/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Apna Chemist</h2>
                      Get Flat Rs 100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/apnachemist.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">at Apna Chemist !</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/health/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Navin Hospitals</h2>
                      Get Flat Rs.50 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/navinHospital.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 "> Cashback at Navin Hospitals </a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="SuperChoice">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>SuperChoice</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/resto/s1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> SuperCash </h2>
                      Choose to earn or use 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/supercash.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">your SuperCash at merchant checkouts!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
       
  
      </div>
 
      <div className="row mt-5" id="Restaurants">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Restaurants</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/resto/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Hira Sweets </h2>
                      Get up to Rs.100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/hirasweets.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback at Hira Sweets!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/resto/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Rolls Mania </h2>
                      Get up to Rs 100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/rollmania.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback at Rolls Mania !</a>
                  </div>
                </div>
                </Link>
        </div>
  
         
  
      </div>
      
      <div className="row mt-5" id="Services">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Services</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/service/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> cleartax </h2>
                      Get upto Rs.15% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/cleartax.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback and Additional discount upto 65% on Tax Filing via cleartax!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/service/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Shiprocket</h2>
                      Get upto 15% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/shiprocket.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">cashback on Shiprocket!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/service/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Gomechanic</h2>
                      Get upto Rs.300 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/gomechanic.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on GoMechanic!</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/service/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Petrol Pumps</h2>
                      Get 5% up to Rs.100
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/petrol.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 "> cashback on fuel transactions!</a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="CashbackOnZIP">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>CashbackOnZIP</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/zip/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Relaxo </h2>
                      Get upto Rs.50 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/relaxo.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback at Relaxo!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/zip/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Reliance SMART</h2>
                      Get Flat Rs 40 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/smart.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback at , Fresh and Sahakari Bhandar stores!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/zip/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Reliance Digital</h2>
                      Get up to Rs 500 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/digital.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback at Reliance Digital !</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/zip/4.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Jio Stores</h2>
                      Get up to Rs 500 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/jiostores.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 "> Cashback at Jio Stores! </a> 
                  </div>
                </div>
                </Link>
        </div>
  
      </div>
 
      <div className="row mt-5" id="BoxOffice">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Box Office</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/box/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Cinepolis </h2>
                      Get 25% 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/cinepolis.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">SuperCash @ Cinepolis!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/box/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Mukta A2 Cinemas</h2>
                      Get 5% up to Rs.50
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/mukta.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0"> Cashback at Mukta Cinemas!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/box/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Gold Cinema</h2>
                      Get up to Rs.100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/goldcinema.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback at Gold Cinema!</a>
  
                  </div>
                </div>
                </Link>
        </div>
      </div>
 
      <div className="row mt-5 mb-5" id="TataNeuStore">
       
       <div className="col-12">
       <div className="row">
          <div className="col-lg-9 col-6 mt-2">  <span style={{fontSize: '28px'}}>Tata Neu Store</span></div>
         
          <div className="col-lg-3 col-6 mt-3 text-end">  <Link to='/dashboard' className="btn btn-outline-primary me-1">See All</Link></div>
        </div>
       </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
              <Link to='/dashboard'> 
                <div class="card rounded-4">
                  <img src="assets/images/tata/1.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Bigbasket </h2>
                      Get upto Rs.100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/bigbasket.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on Bigbasket!</a>
  
                  </div>
                </div>
                </Link> 
        </div>
  
        <div className="col-lg-3 col-6 mt-3">
        <Link to='/dashboard'>  
                <div class="card rounded-4">
                  <img src="assets/images/tata/2.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                   
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> bbdaily</h2>
                      Get upto Rs.100 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/bbdaily.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                      <a class="card-text mb-3  mt-0">Cashback on bbdaily!</a>
                  </div>
                </div>
                </Link>
        </div>
  
          <div className="col-lg-3 col-6 mt-3 ">
          <Link to='/dashboard'>
                <div class="card rounded-4">
                  <img src="assets/images/tata/3.jpg" alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                  <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3">Tata 1Mg</h2>
                      Get upto Rs.500 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/gorsari/1mg.png" alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                  
                    <a class="card-text mb-3 ">Cashback on Tata 1Mg !</a>
  
                  </div>
                </div>
                </Link>
        </div>
  
        <div className="col-lg-3 col-6 mt-3 ">
        <Link to='/dashboard'>
                <div class="card rounded-4">
                <img src="assets/images/tata/4.jpg"  alt="..." class="card-img-top" id="cimg" />
                  <div class="card-body">
                    <div className="row">
                      <div className="col-8">
                      <h2 class="post-title mt-3"> Tata Neu-bbnow</h2>
                      Get upto Rs.50 Cashback 
                      </div>
                      <div className="col-4">
                      <img src="assets/images/tata/tata4.png"  alt="" className="w-100 mt-3" />
                      </div>
                    </div>
                    <a class="card-text mb-3 ">on bbnow on payment via Mobikwik wallet! </a> 
                  </div>
                </div>
                </Link>
        </div> 
  
      </div>

    
  </div>

   <Footer />
     
     
      </>

    );
}

export default Uoffer;