import React from "react";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
import Cgym from "./Cgym";

const Ugym = () => {

    return(
        <>
            <Unavbar />
         <Cgym />
            <Footer/>
    </>
    );
}
export default Ugym;
