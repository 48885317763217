import React from "react";
import { Link } from "react-router-dom";
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
const Ucom = () => {
        return(

        
    <> 
     <Unavbar />
<div className="row">
  <div className="col 12 p-5 bg-dark"> </div>
</div>
      <div className="row">
         <Unav />
          
            <div className="col-lg-9 col-12">
             
               
               <section style={{fontSize: '18px', fontWeight:'500'}}>
                <div className="col-lg-8 card p-3 mt-4 mb-4 me-2 ms-2" >
                 <div className="col-12"> 
                   <h5 className="radiant ">COMMUNICATION UPDATE</h5> <hr />
                      </div>
                 <div className="row">
              
                 <div className="col-12 ">
                  <div className="row">
                    <div className="col-12">
                      <Link to="/communication-update" ><span> <i className="bi bi-journal-text text-danger" />
                        &nbsp;</span> Access your common society documents, Registers, MOM/AGM
                        Report,
                        etc.</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <hr />
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <Link to="/communication-update" ><span> <i className="bi bi-journal-text text-danger" />&nbsp;
                        Society Billing and Accounting - Water bill, Electricity
                        Bill,
                        etc.</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <hr />
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <Link to="/communication-update" > <span> <i className="bi bi-journal-text text-danger" />&nbsp;
                        NOC, Reports &amp; Certificate [ fire, STP, Lift ]</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <hr />
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <Link to="/communication-update" > <span> <i className="bi bi-journal-text text-danger" />
                        &nbsp; Procurement &amp; Purchase Details / Order</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <hr />
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <Link to="/communication-update" ><span> <i className="bi bi-journal-text text-danger" />&nbsp;
                        Pet dog Rules &amp; Registration</span></Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <hr />
                <div className="col-12">
                  <div className="row">
                    <div className="col-12">
                      <Link to="/communication-update" className="article-link"> <span> <i className="bi bi-journal-text text-danger" /> &nbsp; Society Rules &amp;
                        Regulations &amp; For
                        residents</span></Link>
                    </div>
                  </div>
                </div>
              </div> <hr />
              {/* <div className="row mt-4 mb-4">
                <div className="col-12">
                  <Link to="/communication-update" className="btn btn-danger">ALL UPCOMING MEETINGS</Link>
                </div>
              </div> */}
                </div>
                </section> 
                </div>
            </div>
            <Footer />
        
        
       
    </>
    );
}

export default Ucom;