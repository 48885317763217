import React, { useState } from 'react';
import Unav from '../components/Unav';
import Footer from '../components/Footer';
import Unavbar from '../components/Unavbar';
import Select from '../components/Select';
import Code from '../components/Code';
import axios from 'axios';
import Applyform from '../components/Applyform';

function Upet() {
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || { name: 'N/A', phone: 'N/A', email: 'N/A', role: 'N/A', flat: 'N/A', tower: 'N/A' };

  const [formData, setFormData] = useState({
    pet_name: '',
    age: '',
    pet_type: '',
    breed: '',
    sex: '',
    rabies_vaccination_date: '',
    next_due_date_rabies_vaccination: '',
    photos: null,
    owner_pet_photo: null,
    remark: ''
  });
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setFormData({
        ...formData,
        [name]: files[0]
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccess('');

    const formDataToSubmit = new FormData();
    formDataToSubmit.append('role', userDetails.role);
    formDataToSubmit.append('name', userDetails.name);
    formDataToSubmit.append('phone', userDetails.phone);
    formDataToSubmit.append('email', userDetails.email);
    formDataToSubmit.append('flat', userDetails.flat);
    formDataToSubmit.append('tower', userDetails.tower);
    formDataToSubmit.append('pet_name', formData.pet_name);
    formDataToSubmit.append('pet_age', formData.age);
    formDataToSubmit.append('pet_type', formData.pet_type);
    formDataToSubmit.append('breed', formData.breed);
    formDataToSubmit.append('gender', formData.sex);
    formDataToSubmit.append('rabies_vaccination_date', formData.rabies_vaccination_date);
    formDataToSubmit.append('next_due_date_rabies_vaccination', formData.next_due_date_rabies_vaccination);
    formDataToSubmit.append('photos', formData.photos);
    formDataToSubmit.append('owner_pet_photo', formData.owner_pet_photo);
    formDataToSubmit.append('remark', formData.remark);

    try {
      await axios.post('https://amrapali-zodiac.in/admin-panel/api/register-pet', formDataToSubmit, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      setSuccess('Pet registered successfully!');
      // Reset form fields
      setFormData({
        pet_name: '',
        age: '',
        pet_type: '',
        breed: '',
        sex: '',
        rabies_vaccination_date: '',
        next_due_date_rabies_vaccination: '',
        photos: null,
        owner_pet_photo: null,
        remark: ''
      });
    } catch (err) {
      setError(err.response?.data?.message || 'Submission failed');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Unavbar />
      <div className="row">
        <div className="col-12 p-5 bg-dark"> </div>
      </div>
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
          <Applyform />
          <div className="row me-3 mt-3 mb-3 ms-2">
            <div className="col-12 card p-3">
              <div className="row">
                <div className="col-12">
                  <h5 className="radiant">Register Your Pet</h5>
                  <hr />
                </div>
                <div className="row">
                  <div className="col-lg-4 col-12">
                    <label className="mt-1 mb-2">
                      <b>Do you have pet registration before?</b> <span className="text-danger">*</span>
                    </label>
                    <Select />
                  </div>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                          <label className="mt-2"><b>I am an/a</b></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <input type="text" className="w-100 form-control text-uppercase" value={userDetails.role} disabled />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                          <label htmlFor="account-fn"> <b>Name</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <input className="w-100 form-control text-uppercase" type="text" name="name" value={userDetails.name} disabled />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                          <label htmlFor="account-fn"> <b>Phone</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <div className="row">
                            <div className="col-4">
                              <Code />
                            </div>
                            <div className="col-8">
                              <input className="w-100 form-control text-uppercase" type="text" name="phone" value={userDetails.phone} disabled />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                          <label htmlFor="account-fn"> <b>E-Mail ID:</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <input className="form-control text-uppercase" type="text" id="account-fn" placeholder="Enter Email" value={userDetails.email} disabled />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-3">
                          <label htmlFor="account-fn"> <b>Flat Number</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <input className="form-control text-uppercase" type="text" id="account-fn" placeholder="Enter Flat" value={userDetails.flat} disabled />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-3 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12 mt-2">
                          <label htmlFor="account-fn"> <b>Tower</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12 mt-2">
                          <input className="form-control text-uppercase" type="text" id="account-fn" value={userDetails.tower} disabled />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-4 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12">
                          <label htmlFor="pet_name"><b>Pet Name</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12">
                          <input
                            className="form-control"
                            type="text"
                            name="pet_name" // Fixed name attribute
                            value={formData.pet_name}
                            onChange={handleChange}
                            placeholder="Enter Pet Name"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-4 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12">
                          <label htmlFor="age"><b>Pet Age</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12">
                          <input
                            className="form-control"
                            type="number"
                            name="age"
                            value={formData.age}
                            onChange={handleChange}
                            placeholder="Enter Age"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-4 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12">
                          <label htmlFor="pet_type"><b>Pet Type</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12">
                          <input
                            className="form-control"
                            type="text"
                            name="pet_type"
                            value={formData.pet_type}
                            onChange={handleChange}
                            placeholder="Enter Type"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-4 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12">
                          <label htmlFor="breed"><b>Breed</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12">
                          <input
                            className="form-control"
                            type="text"
                            name="breed"
                            value={formData.breed}
                            onChange={handleChange}
                            placeholder="Enter Breed"
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-4 form-group">
                      <div className="row">
                        <div className="col-lg-4 col-12">
                          <label htmlFor="sex"><b>Select Gender</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-8 col-12">
                          <div className="row text-center">
                            <div className="col-6">
                              <label>
                                <input
                                  type="radio"
                                  name="sex"
                                  value="male"
                                  checked={formData.sex === 'male'}
                                  onChange={handleChange}
                                  required
                                />{' '}
                                Male
                              </label>
                            </div>
                            <div className="col-6">
                              <label>
                                <input
                                  type="radio"
                                  name="sex"
                                  value="female"
                                  checked={formData.sex === 'female'}
                                  onChange={handleChange}
                                  required
                                />{' '}
                                Female
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-4 form-group">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <label htmlFor="rabies_vaccination_date"><b>Rabies Vaccination Date</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-6 col-12">
                          <input
                            className="form-control"
                            type="date"
                            name="rabies_vaccination_date"
                            value={formData.rabies_vaccination_date}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-4 form-group">
                      <div className="row">
                        <div className="col-lg-7 col-12">
                          <label htmlFor="next_due_date_rabies_vaccination"><b>Next Due Date Rabies Vaccination</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-5 col-12">
                          <input
                            className="form-control"
                            type="date"
                            name="next_due_date_rabies_vaccination"
                            value={formData.next_due_date_rabies_vaccination}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-4 form-group">
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <label htmlFor="photos"><b>Add Pet's Photos</b> <span className="text-danger">*</span></label>
                        </div>
                        <div className="col-lg-6 col-12">
                          <input
                            className="form-control"
                            type="file"
                            name="photos"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-4 form-group">
                      <div className="row">
                        <div className="col-lg-5 col-12">
                          <label htmlFor="owner_pet_photo"><b>Owner with Pet Photo</b></label>
                        </div>
                        <div className="col-lg-7 col-12">
                          <input
                            className="form-control"
                            type="file"
                            name="owner_pet_photo"
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 mt-4 mb-4 form-group">
                      <div className="row">
                        <div className="col-lg-12 col-12">
                          <label htmlFor="remark"><b>Remarks:</b></label>
                        </div>
                        <div className="col-lg-12 col-12">
                          <textarea
                            className="form-control"
                            id="remark"
                            name="remark"
                            rows={3}
                            value={formData.remark}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-lg-3 col-12">
                      <input className="bg-primary text-white form-control" type="submit" value="Submit" disabled={loading} />
                    </div>
                  </div>
                </form>
                {error && <div className="text-danger form-control  text-center">{error}</div>}
                {success && <div className="text-success form-control  text-center">{success}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Upet;
