import React from "react"
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Code from "../components/Code";
import { Helmet } from "react-helmet";

const Forgototp = () => {
  return (

    <>
       <Helmet>
        <title>Forgot Password - Paras Tierea</title>
        <meta name="description" content="Retrieve your password for your Samridhi Grand Avenue account. Follow the instructions to securely and conveniently regain access to your account." />
      </Helmet>

      <style dangerouslySetInnerHTML={{ __html: "body{background: rgb(63,94,251); background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);\n        }\n        .navbar {background-color: #babebf;} .hero-section{height: auto;}" }} />

      <Navbar />

      <div className="container hero-section">
        <div className="row justify-content-center">
          <div className="col-lg-4 col-12">
            <div className="box" style={{ backgroundColor: 'rgb(222, 243, 209)' }}>    
              <div className="row justify-content-center">
               <div className="col-12">
               <img src="assets/images/pass.png" alt="" className="w-100" />
               </div>
               <div className="col-12 text-center">
                 <span style={{fontSize:'20px'}}>Forgot Password ?
                    </span>
               </div>
            
               <div className="row justify-content-center mt-4 mb-5">
               <div className="col-11 ">
                <label htmlFor="" className=""> <b>Email (Phone Number)</b><span className="text-danger"> *</span></label>
                
               </div>
               
             <div className="col-11">
             <div className="row">
                  <div className="col-4 mt-2">
                    <Code />
                  </div>
                  <div className="col-8 mt-2">
                  <input type="text"  className="form-control "
                placeholder="Enter Phone / Email"
                />
                  </div>
                </div>
            
             </div>
             <div className="col-11 ">
              
            <Link to='/forgot-pwd' className="btn btn-primary w-100 mt-4 ">Reset Password</Link> 
                
               </div>
               <div className="col-11 text-center mt-4" style={{fontSize:'17px'}}>
               
         
                 Already Register ?   <Link to='/login'>  <span className="text-primary">Back To Login </span> </Link> 
               </div>
              </div>


              </div>
            
            </div>
          </div>
        </div>
      </div>



    </>
  )
}

export default Forgototp;
