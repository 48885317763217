import React from "react";


const Csociety = () => {

    return(
        <>
           <section className="hero-section" id="section_1">
          <div className="section-overlay" />
          <div className="video-wrap">
            <img src="assets/images/low.jpg" alt="" className="custom-video" id="imgym" />
          </div>
        </section>
        <section className="about-section">
          <div className="container">
            <h2 className="text-danger mt-2">Housing Society Rules and Regulations for Tenants</h2>
            <hr />
          </div>
        </section>
        <section>
          <div className="container">
            <p className="nmn" style={{textAlign: 'justify', fontWeight: 500}}>Housing society rules and regulations for tenants are like
              the unwritten rules of a close-knit community. They’re not just a set of do’s and don’ts; they’re
              the secret sauce that keeps everyone living together in harmony. In this blog, we’ll unravel the
              mysteries of tenant rights and responsibilities that often seem complex
              but are quite straightforward. </p> <br />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">
              Understanding Renters’ Rights and Responsibilities in Housing Societies</h6>
            <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">When it comes to tenant rights within housing
              societies, it might seem like a complex journey to navigate. However, tenants need to have a good
              grasp of their rights to ensure a smooth and respectful experience during their tenancy. This
              knowledge plays a vital role in fostering a friendly and cooperative relationship between tenants
              and landlords, ultimately preserving the harmony within housing societies.</p><br />
            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3"><b>1. Legal
                Agreements and Documentation<b /></b></h6><b><b>
                <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">But here’s the thing – it’s not enough to
                  just have a lease agreement. You need your very own copy of it. This isn’t just some paper; it’s
                  proof of your rights and a reference point if there are ever any disagreements. With your copy in
                  hand, you’re always armed with the info you need to stand up for your rights and make smart choices
                  about your place.</p><br />
                <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">For tenants, the lease agreement is like a
                  superhero’s shield, making sure your rights are crystal clear and safeguarded. It spells out the
                  terms of your stay, including the rent, how long you can stay, and other important details. It’s
                  your roadmap for a smooth tenancy.</p><br />
                <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">In the complex world of tenant-landlord
                  relationships, there’s one document that plays a pivotal role in ensuring everyone is on the same
                  page – the lease agreement. This isn’t just a bunch of legal jargon; it’s your safety net,
                  protecting both you and your landlord.</p><br />
                <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3"><b>2.
                    Living Conditions and Maintenance<b /></b></h6><b><b>
                    <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">A fundamental right of every tenant is to
                      reside in a space that is not only safe but also conducive to a healthy living environment. The
                      concept of habitability extends beyond the mere structure of the residence; it encompasses aspects
                      such as sanitation, security, and proper maintenance. Tenants are entitled to a home that is free
                      from hazards and defects, a place where they can live comfortably and securely.</p><br />
                    <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">However, the responsibility of maintaining
                      this habitability is not a one-sided affair. While landlords are obligated to ensure that the
                      property is in a livable condition at the start of the tenancy, tenants too play a pivotal role.
                      They must maintain the cleanliness and integrity of the property during their stay. In cases where
                      repairs or restorations become necessary, tenants must communicate with their landlords to address
                      these issues promptly. The synergy between tenant and landlord in maintaining the property not only
                      fosters a healthy living environment but also preserves the value and longevity of the property.
                    </p><br />
                    <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3"><b>3.
                        Privacy and Respec<b /></b></h6><b><b>
                        <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">Privacy in a rental agreement is a right, not
                          a privilege. Tenants have the unequivocal right to enjoy their rented space without unwarranted
                          intrusions. </p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">The landlord’s right to access the property
                          is not absolute and must be exercised with respect and consideration for the tenant’s privacy. This
                          involves providing adequate notice before entering the premises, typically 24 hours, and doing so at
                          reasonable times. Such respect for boundaries is pivotal in fostering a relationship based on mutual
                          respect and understanding.</p><br />
                        <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">Moreover, this right to privacy extends to
                          the tenant’s lifestyle choices, including the right to host guests and live their lives without
                          undue interference, as long as it adheres to the agreed-upon terms of the lease and the societal
                          norms. This respect for privacy is a cornerstone of trust in the tenant-landlord relationship,
                          ensuring that the tenants feel genuinely at home in their rented space.</p><br />
                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">
                          Responsibilities and Duties of Tenants</h6>
                        <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">Living in society comes with its share of
                          responsibilities and duties, especially for tenants. These duties, if adhered to diligently, ensure
                          a harmonious and respectful living environment for all residents. Let’s delve into the key
                          responsibilities every tenant should be aware of:</p>
                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3"><b>1.
                            Financial Obligations<b /></b></h6><b><b>
                            <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">The cornerstone of a tenant’s
                              responsibilities lies in their financial commitments. Timely payment of rent is paramount. Rent is
                              not just a fee for occupying a space; it’s a commitment to your landlord and a reflection of your
                              reliability. </p><br />
                            <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">Besides the monthly rent, tenants often have
                              to manage utility bills, which may include electricity, water, and gas. These are not just expenses
                              but responsibilities that ensure the smooth functioning of the household. It’s crucial to understand
                              the terms of your lease agreement regarding these payments. Some agreements might include utilities
                              in the rent, while others would require tenants to pay them separately.</p><br />
                            <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">Moreover, tenants should be aware of any
                              other financial obligations agreed upon in the lease, such as parking charges or maintenance fees.
                              Understanding these nuances and adhering to them is not only a legal requirement but also a mark of
                              a responsible tenant.</p><br />
                            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3"><b>2.
                                Maintenance and Care of Property<b /></b></h6><b><b>
                                <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">A home is more than just walls and a roof;
                                  it’s a space that demands care and attention. As a tenant, maintaining the property is a key
                                  responsibility. </p><br />
                                <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">This doesn’t just mean keeping it clean but
                                  also ensuring that all fixtures and appliances are in working order. Reporting any damages
                                  immediately to the landlord is not only considerate but often a requirement in rental agreements.
                                  Whether it’s a leaking faucet or a malfunctioning appliance, prompt reporting can prevent minor
                                  issues from becoming major problems.</p><br />
                                <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">Tenants should also undertake minor
                                  maintenance tasks themselves, such as replacing light bulbs or keeping drains clear. However, for
                                  more significant repairs or issues, it’s important to coordinate with the landlord. Remember, taking
                                  good care of the property is not just about avoiding damage; it’s about respecting the space you
                                  call home.</p><br />
                                <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3"><b>3.
                                    Societal Conduct and Compliance<b /></b></h6><b><b>
                                    <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">Living in society is akin to being part of a
                                      community. As such, tenants must conduct themselves in a manner that respects the norms and rules of
                                      the society. This means being mindful of noise levels, disposing of waste properly, and using common
                                      areas with consideration for others. Each society might have its own set of rules or a code of
                                      conduct, and tenants need to familiarise themselves with these.</p><br />
                                    <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">Respecting neighbours, adhering to society
                                      guidelines on the use of amenities like gyms, pools, or parks, and participating in community
                                      activities when possible, all contribute to a positive living environment. Compliance with these
                                      societal norms is not just about following rules; it’s about fostering a community spirit and
                                      ensuring a peaceful coexistence with your neighbours.</p><br />
                                    <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">
                                      Understanding Amenities and Facilities</h6>
                                    <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">Living in society offers numerous benefits,
                                      with access to various amenities being one of the most significant. Tenants enjoy the privilege of
                                      using facilities that enhance their living experience. However, this access comes with an
                                      understanding of and adherence to certain guidelines.</p><br />
                                    <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3"><b>Access
                                        to Society Amenities<b /></b></h6><b><b>
                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}>Tenants enjoy the
                                          benefit of using common facilities like parks, gyms, swimming pools, and community halls.</li>
                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}>These amenities
                                          enhance living experiences and foster community spirit..</li>
                                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">
                                          <b>Adherence to Society Guidelines<b>
                                            </b></b></h6><b><b>
                                            <li style={{textAlign: 'justify', listStyleType: 'square'}}>Tenants must follow
                                              the rules set by the housing society for using these facilities.</li>
                                            <li style={{textAlign: 'justify', listStyleType: 'square'}}>Guidelines may
                                              include specific hours for facility usage or rules for booking communal spaces.</li>
                                            <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">
                                              <b>Responsible Use of Facilities<b>
                                                </b></b></h6><b><b>
                                                <li style={{textAlign: 'justify', listStyleType: 'square'}}>Tenants should use
                                                  amenities responsibly, respecting operational hours and safety protocols..</li>
                                                <li style={{textAlign: 'justify', listStyleType: 'square'}}>Maintaining
                                                  decorum, such as controlling noise levels during events in common areas, is essential.</li>
                                                <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">
                                                  <b>Contribution to Maintenance<b>
                                                    </b></b></h6><b><b>
                                                    <li style={{textAlign: 'justify', listStyleType: 'square'}}>Tenants have a role
                                                      in the upkeep of amenities, possibly through maintenance fees or adhering to cleanliness standards.
                                                    </li>
                                                    <li style={{textAlign: 'justify', listStyleType: 'square'}}>Ensuring facilities
                                                      are left in good condition is a shared responsibility.</li>
                                                    <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3"><b>3.
                                                        Societal Conduct and Compliance<b /></b></h6><b><b>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}>In case of unclear
                                                          or restrictive rules, tenants should seek clarification from the society’s managing committee</li>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}>Participation in
                                                          society meetings can ensure tenants’ concerns and suggestions are considered.</li>
                                                        <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">Access to amenities in society is a privilege
                                                          that enhances the living experience of tenants. However, it is accompanied by a responsibility to
                                                          use these facilities considerately and by society’s rules. By doing so, tenants contribute to
                                                          maintaining a harmonious and enjoyable environment for all residents.</p><br />
                                                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">Eviction
                                                          and Legal Recourse</h6>
                                                        <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">In the realm of housing societies,
                                                          understanding the protocols for eviction and the legal rights available to tenants is crucial. This
                                                          knowledge not only ensures a smooth tenant-landlord relationship but also safeguards the interests
                                                          of both parties. Let’s delve into the intricacies of eviction procedures and the legal recourse
                                                          available to tenants in case of disputes.</p><br />
                                                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">Eviction
                                                          Protocols</h6>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}><strong>Non-payment
                                                            of Rent:</strong> A tenant can be evicted for not paying rent as agreed in the lease. Landlords
                                                          must provide a notice, allowing time for payment or vacating.</li>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}><strong>Lease
                                                            Violations:</strong> Activities like subletting without permission, illegal use of the property,
                                                          or causing significant damage can lead to eviction. Tenants should receive a formal notice
                                                          specifying the violation and a timeframe to rectify the issue.</li>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}><strong>Landlord’s
                                                            Personal Use:</strong>If the landlord needs the property for personal reasons or for major.</li>
                                                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">Legal
                                                          Rights and Complaints</h6>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}><strong>Seeking
                                                            Resolution:</strong> enants should first attempt to resolve disputes through direct
                                                          communication with the landlord. If unsuccessful, they can consult legal aid or a property lawyer.
                                                        </li>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}>
                                                          <strong>Documentation and Records:</strong> Maintaining detailed records of all interactions,
                                                          payments, and relevant documents is crucial for supporting the tenant’s case.
                                                        </li>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}><strong>Filing
                                                            Complaints:</strong>In cases of harassment or illegal eviction attempts, tenants can file
                                                          complaints with housing authorities or the police, and approach the civil court for lease-related
                                                          disputes.</li>
                                                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">The Role of
                                                          the Housing Society in Tenant-Landlord Relationships
                                                        </h6>
                                                        <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">In the dynamic world of housing societies,
                                                          understanding the role and limitations of the society itself in tenant-landlord relationships is
                                                          crucial. While the direct interaction typically lies between the tenant and the landlord, the
                                                          housing society plays a significant, albeit specific, role in this ecosystem. Here are the key
                                                          aspects of this role:</p><br />
                                                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">Guardian of
                                                          Collective Interests</h6>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}>Enforces society’s
                                                          rules and regulations equally among all residents, including tenants.</li>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}>Ensures maintenance
                                                          of decorum, safety, and living standards through guidelines on noise levels, use of common areas,
                                                          and parking norms.</li>
                                                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">Limited
                                                          Authority in Tenant-Landlord Agreements</h6>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}>Does not interfere
                                                          in rental negotiations or specific terms of tenant-landlord agreements.</li>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}>Lacks legal power
                                                          to impose additional conditions or alter rental agreements.</li>
                                                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">Upholding
                                                          Impartiality</h6>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}>Maintains fair
                                                          treatment for all residents, avoiding discrimination based on race, religion, or marital status.
                                                        </li>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}>Ensures equal
                                                          application of rules to both owners and tenants.</li>
                                                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">Role in
                                                          Dispute Resolution</h6>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}>Acts as a mediator
                                                          or facilitator in conflicts, without enforcing resolutions.</li>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}>Provides support
                                                          with documentation and information about societal norms when required.</li>
                                                        <h6 style={{color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify'}} className="my-3">
                                                          Non-Judicial Entity</h6>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}>Cannot act as a
                                                          legal authority in disputes between tenants and landlords.</li>
                                                        <li style={{textAlign: 'justify', listStyleType: 'square'}}>Focuses on
                                                          fostering dialogue and understanding rather than legal intervention</li><br />
                                                        <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">Understanding housing society rules and
                                                          regulations for tenants is not just about navigating the complexities of legal agreements or
                                                          adhering to societal norms. It’s about creating a respectful and harmonious environment for both
                                                          tenants and landlords.</p><br />
                                                        <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">From ensuring the right to a habitable
                                                          residence to maintaining the decorum of society, each aspect plays a crucial role in fostering a
                                                          peaceful coexistence. For tenants, understanding these rules means being better equipped to protect
                                                          their rights and fulfil their responsibilities.</p><br />
                                                        <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">Furthermore, navigating these complexities
                                                          can be challenging, which is where NoBroker’s legal services can be invaluable. Whether it’s
                                                          interpreting lease agreements, resolving disputes, or simply seeking advice on tenant rights and
                                                          duties, NoBroker offers expert guidance. </p><br />
                                                        <p style={{textAlign: 'justify', fontWeight: 500}} className="my-4">By leveraging such professional assistance,
                                                          tenants can ensure their tenancy is not only legally compliant but also conducive to a harmonious
                                                          living experience within their housing society.</p><br />
                                                      </b></b></b></b></b></b></b></b></b></b></b></b></b></b></b></b></b></b></b></b></b></b></div><b><b><b>
              </b></b></b></section>
           
    </>
    );
}
export default Csociety;
