
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
import Code from "../components/Code";

function Uexit() {
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || { name: 'N/A', phone: 'N/A', email: 'N/A', role: 'N/A', flat: 'N/A' };

  return (

    <>
  
      <Unavbar />
      <div className="row">
        <div className="col 12 p-5 bg-dark"> </div>
      </div>
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
            
        <div className="row mt-2 me-2 ms-1">
          
       
          <div className="col-12 card p-3 mb-4">
          <div className="row">
            <div className="col-12"> 
          <h5 className="radiant ">Assets Handover to Maintenance Teams</h5> <hr />
            </div>
          
                  </div>


                 <div className="row">
                  <div className="col-lg-6 col-12 mt-1  form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label className="mt-2"><b>I am an/a</b> </label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input type="text" className="w-100 form-control text-uppercase" value={userDetails.role} disabled />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-1  form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2">
                        <label htmlFor="account-fn"> <b>Name</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input className="w-100 form-control text-uppercase" type="text"
                          name="name" value={userDetails.name} disabled />

                      </div>

                    </div>
                  </div>
                  <div className="col-lg-6 col-12 mt-3  form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2 ">
                        <label htmlFor="account-fn"> <b>Phone</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">

                        <div className="row">
                          <div className="col-4">
                            <Code />
                          </div>
                          <div className="col-8">
                            <input className="w-100 form-control text-uppercase" type="text"
                              name="phone" value={userDetails.phone} disabled />
                          </div>
                        </div>


                      </div>

                    </div>

                  </div>
                  <div className="col-lg-6 col-12 mt-3  form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2 ">
                        <label htmlFor="account-fn"> <b>E-Mail ID:</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control text-uppercase"
                          type="text"
                          id="account-fn"
                          placeholder="Enter Email"
                          value={userDetails.email} disabled
                        />

                      </div>

                    </div>



                  </div>
                  <div className="col-lg-6 col-12 mt-3  form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-3 ">
                        <label htmlFor="account-fn"> <b>Flat Number</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control text-uppercase"
                          type="text"
                          id="account-fn"
                          placeholder="Enter Flat"
                          value={userDetails.flat} disabled
                        />

                      </div>

                    </div>



                  </div>
                  <div className="col-lg-6 col-12 mt-3  form-group">
                    <div className="row">
                      <div className="col-lg-4 col-12 mt-2 ">
                        <label htmlFor="account-fn"> <b>Tower</b> <span className="text-danger">*</span></label>
                      </div>
                      <div className="col-lg-8 col-12 mt-2">
                        <input
                          className="form-control text-uppercase"
                          type="text"
                          id="account-fn"
                          value={userDetails.tower} disabled
                        />

                      </div>

                    </div>

                   
                  </div>
                 <div className="col-lg-6 col-12 mt-3 form-group">
                     <div className="row">
                     <div className="col-lg-4 col-12 mt-2 ">
                       <label htmlFor="account-fn"> <b>Attachment </b> <span className="text-danger">*</span></label>
                       </div>
                       <div className="col-lg-8 col-12 mt-2">
                       <input
                     className="form-control"
                     type="file"
                     id="account-fn"
                     placeholder="Enter Breed"
                     required=""
                   />
               
                       </div>
                      
                     </div>
                   
                 
                 
                   </div>
                   <div className="col-lg-6 col-12 mt-3 form-group">
                     <div className="row">
                     <div className="col-lg-6 col-12 mt-3 ">
                       <label htmlFor="account-fn"> <b>Parking Sticker issued</b> <span className="text-danger">*</span></label>
                       </div>
                       <div className="col-lg-6 col-12 mt-2">
                        <div className="row text-center">
                          <div className="col-6">
                          <label> 
                          <input type="radio" name="sex" defaultValue="male" required="" /> Yes
                          </label>
                          </div>
                          <div className="col-6">
                          <label>
                          <input type="radio" name="sex" defaultValue="female" required="" />{" "}
                         No
                     
                    </label>
                          </div>
                        </div>
                      
                    
               
                       </div>
                      
                     </div>
                   
                 
                 
                   </div>
                   <div className="col-lg-6 col-12  mt-3 form-group">
                 <div className="row">
                     <div className="col-lg-6 col-12 mt-3 ">
                       <label htmlFor="account-fn"> <b>RFID TAG [Boom Barrier]</b></label>
                       </div>
                       <div className="col-lg-6 col-12 mt-2">
                        <div className="row text-center">
                          <div className="col-6">
                          <label> 
                          <input type="radio" name="sex" defaultValue="male" required="" /> Yes
                          </label>
                          </div>
                          <div className="col-6">
                          <label>
                          <input type="radio" name="sex" defaultValue="female" required="" />{" "}
                         No
                     
                    </label>
                          </div>
                        </div>
                      
                    
               
                       </div>
                      
                     </div>
                   
                   </div>
                   <div className="col-lg-6 col-12 mt-3 form-group">
                     <div className="row">
                     <div className="col-lg-6 col-12 mt-3 ">
                       <label htmlFor="account-fn"> <b>Access Card</b></label>
                       </div>
                       <div className="col-lg-6 col-12 mt-2">
                        <div className="row text-center">
                          <div className="col-6">
                          <label> 
                          <input type="radio" name="card" defaultValue="male" required="" /> Yes
                          </label>
                          </div>
                          <div className="col-6">
                          <label>
                          <input type="radio" name="card" defaultValue="female" required="" />{" "}
                         No
                     
                    </label>
                          </div>
                        </div>
                      
                    
               
                       </div>
                      
                     </div>
    
                   </div>
                  </div>
                  <div className="row justify-content-end">
                     <div className="col-lg-3 col-12 mt-4 mb-3">
                   <input className="bg-primary text-white form-control me-5  "  type="submit" defaultValue="Submit" />
                   </div>
                  </div>
                  
           </div>
           </div>


       
        </div>
</div>

<Footer />
    </>
  );
}

export default Uexit;
