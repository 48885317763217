import React from 'react';
import Unav from '../components/Unav';
import Unavbar from '../components/Unavbar';

const Dashboard = () => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || { name: 'N/A', phone: 'N/A', email: 'N/A', role: 'N/A', flat: 'N/A' };

  return (
    <>

    
<Unavbar />
      <div className="row">
        <div className="col 12 p-5 bg-dark"> 
      </div>
      <div className="row">
        <Unav />
        <div className="col-lg-9 col-12">
            
    
    <div className="dashboard">
      <h1>Welcome, {userDetails.name}</h1>
      <div className="user-info">
      
        <p><b>Role:</b> {userDetails.role}</p>
        <p><b>Name:</b> {userDetails.name}</p>
        <p><b>Phone:</b> {userDetails.phone}</p>
        <p><b>Email:</b> {userDetails.email}</p>
        <p><b>Flat:</b> {userDetails.flat}</p>
       
      </div>
    </div>
    </div></div> </div>
    </>
  );
};

export default Dashboard;
