import React from "react";
import { Link } from "react-router-dom";

const Socevent = () => {
        return(

    <> 
            <div className="col-lg-8 ">
              <article>
                <div className="line-item hf-item-odd clearfix">
                  <div className="content-image">
                    <a className="image-link article-link" href>
                      <img className="rounded box" src="assets/images/15august.avif" alt="img" id="imgthum" />
                      <span className="overlay article-overlay" />
                    </a>
                  </div>
                  <div className="hf-info">
                    <div className="hf-category">
                      <Link to="/event" className="text-danger">AUGUST
                      </Link>
                    </div>
                    <h2 className="post-title">
                      <Link to="/event" className="article-link" >
                        Up Coming Event Independence Day
                        <span className="overlay article-overlay" />
                      </Link>
                    </h2>
                    <div className="summary">
                      Independence Day is celebrated annually on 15 August as a public holiday in
                      India
                      commemorating the nation's....
                    </div>
                    <Link to="/event" className="btn btn-danger mt-2">Read More</Link>
                  </div>
                </div>
              </article>
              <article>
                <div className="line-item hf-item-odd clearfix">
                  <div className="content-image">
                    <Link to="/event" className="image-link article-link" >
                      <img className="rounded box" src="assets/images/holi.avif" alt="img" id="imgthum" />
                      <span className="overlay article-overlay" />
                    </Link>
                  </div>
                  <div className="hf-info">
                    <div className="hf-category">
                      <Link to="/event" className="text-danger"> MARCH</Link>
                    </div>
                    <h2 className="post-title">
                      <Link to="/event" className="article-link">
                        Holi Festival 2024 :
                        <span className="overlay article-overlay" />
                      </Link>
                    </h2>
                    <div className="summary">
                      Holi is a popular and significant Hindu festival celebrated as the Festival of
                      Colours,
                      Love, and Spring. It celebrates...
                    </div>
                    <Link to="/event" className="btn btn-danger mt-2">Read More</Link>
                  </div>
                </div>
              </article>
              <article>
                <div className="line-item hf-item-odd clearfix">
                  <div className="content-image">
                    <a className="image-link article-link" href>
                      <img className="rounded box" src="assets/images/newyear.jpeg" alt="img" id="imgthum" />
                      <span className="overlay article-overlay" />
                    </a>
                  </div>
                  <div className="hf-info">
                    <div className="hf-category">
                      <Link to="/event" className="text-danger">JANUARY</Link>
                    </div>
                    <h2 className="post-title">
                      <Link to="/event" className="article-link" >
                        New Year Party 2024 :
                        <span className="overlay article-overlay" />
                      </Link>
                    </h2>
                    <div className="summary">
                      The New Year is the time or day at which a new calendar year begins and the
                      calendar's
                      year count increments by one....
                    </div>
                    <Link to="/event" className="btn btn-danger mt-2">Read More</Link>
                  </div>
                </div>
              </article>
              <article>
                <div className="line-item hf-item-odd clearfix">
                  <div className="content-image">
                    <a className="image-link article-link" href >
                      <img className="rounded box" src="assets/images/crimas.avif" alt="img" id="imgthum" />
                      <span className="overlay article-overlay" />
                    </a>
                  </div>
                  <div className="hf-info">
                    <div className="hf-category">
                      <Link to="/event" className="text-danger">DECEMBER</Link>
                    </div>
                    <h2 className="post-title">
                      <Link to="/event" className="article-link">
                        Merry Christmas Celebration
                        <span className="overlay article-overlay" />
                      </Link>
                    </h2>
                    <div className="summary">
                      Merry Christmas" is a spoken or written greeting traditionally used on or before
                      the
                      Christmas holiday....
                    </div>
                    <Link to="/event" className="btn btn-danger mt-2">Read More</Link>
                  </div>
                </div>
              </article>
            </div>
          
    </>
    );
}

export default Socevent;