import React from "react";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
import Cparking from "./Cparking";

const Ucparking = () => {

    return(
        <>
            <Unavbar />
            <Cparking />
            <Footer/>
    </>
    );
}
export default Ucparking;
