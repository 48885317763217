import React from "react";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
import Cswimming from "./Cswimming";

const Uswimming = () => {

    return(
        <>
            <Unavbar />
           <Cswimming />
            <Footer/>
    </>
    );
}
export default Uswimming;
