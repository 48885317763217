import React from "react"
import Unav from "../components/Unav";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
const Urules = () => {

  
    return (

      <>
     <Unavbar />
      <div className="row">
        <div className="col 12 p-5 bg-dark"> </div>
      </div>
      <div className="row">
        <Unav />
            <div className="col-lg-9 col-12">
              <div className="row p-3">
                <div className="col-12  ">
                     <h4 className="radiant text-uppercase mt-3">Society Rules & Regulations For Residents</h4>
                        
                </div>
               

                </div>
                <div className="12">
          
            <div className="row">
            <div className="col-lg-6 col-12 mt-4">
              <div className="box p-3 mb-4 ">
                <Link to="/pet"> <span><i className="bi bi-journal-text text-danger" /> &nbsp;
                </span>Apartments Pet Rules – Rights &amp;
                  Responsibility of Pet... <span className="text-danger">
                    Read More</span></Link>
                <hr />
                <Link to="/law"> <span> <i className="bi bi-journal-text text-danger" /> &nbsp;</span>
                  Society Bylaws for Internal
                  Repairs: A Comprehen...<span className="text-danger"> Read
                    More</span></Link>
                <hr />
                <Link to="/society"> <span>
                  <i className="bi bi-journal-text text-danger" />  &nbsp;</span>
                  Society
                  Rules &amp; Regulations &amp;
                  For Tenants...<span className="text-danger"> Read
                    More</span></Link>
                <hr />
                <Link to="/parking" > <span><i className="bi bi-journal-text text-danger" />
                  &nbsp;</span>Reserve
                  Parking in Society:
                  Understanding Rules &amp; Law...<span className="text-danger">
                    Read More</span></Link>
                <hr />
                <Link to="/election"> <span> <i className="bi bi-journal-text text-danger" /> &nbsp;</span>
                  Election Procedure For &amp; Society...<span className="text-danger"> Read
                    More</span></Link>
                <hr />
                <Link to="/duties"> <span> <i className="bi bi-journal-text text-danger" />
                  &nbsp;</span>Duties
                  Residents in Society...<span className="text-danger"> Read
                    More</span></Link>
                <hr />
                <Link to="/" className="text-danger"> See all &nbsp;<i className="bi bi-arrow-right" /></Link>
              </div>
            </div>
            <div className="col-lg-5 col-12 mt-4 ">
              <div className="row">
                <div className="col-12">
                  <div className="box p-4" style={{ backgroundColor: '#d3d3d3' }}>
                    <h2 className="post-title">
                      <Link to="/gym" className="article-link mb-1" >
                        GYM RULES &amp; REGULATIONS
                      </Link>
                    </h2>
                    <div className="summary" id="su">
                      All gym users must be properly attired in standard workout clothes i.e. Tank
                      tops, T-shirts, shorts, leotards with running or
                      cross training shoes (not use outside the gym) while in exercise area...
                    </div>
                    <Link to="/gym" className="btn btn-danger mt-2"> Expolore Now</Link>
                  </div>
                </div>
                <div className="col-12 mt-4">
                  <div className="box p-4" style={{ backgroundColor: '#d3d3d3' }}>
                    <h2 className="post-title ">
                      <Link to="/swimming" className="article-link mb-1">
                        SWIMMING POOL RULES &amp; REGULATIONS
                      </Link>
                    </h2>
                    <div className="summary" id="su">
                      Swimming Pool Rules and Regulations in a Residential Society Come summer, a
                      society’s swimming pool is an
                      entertaining and vibrant hangout for children as well as adults...
                    </div>
                    <Link to="/swimming" className="btn btn-danger mt-2">Expolore Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
                </div>
            </div>
            </div>
        
            <Footer />
      </>
    );
}

export default  Urules;