import React from "react"
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";

const Uinbox = () => {

  return (

    <>
    
    <Unavbar />
      <div className="row">
        <div className="col 12 p-5 bg-dark"> </div>
      </div>
      <div className="row">
        <Unav />
            <div className="col-lg-9 col-12">

          <div className="row mt-3 me-2 ms-1">
          <div className="col-12 card p-3 mb-4">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">S.N.</th>
                    <th scope="col">TITLE</th>
                    <th scope="col">STATUS</th>
                    <th scope="col">DATE</th>
                    <th scope="col">TIME</th>
                    <th scope="col">ATTACHMENT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                  <th scope="row">1</th>
                   <tb></tb>
                   <tb></tb>
                   <tb></tb>
                   <tb></tb>
                   <tb></tb>
                   <tb></tb>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                  </tr>
                </tbody>
              </table>
             
             
            
          </div>
          </div> 
      
</div>
</div>

<Footer />
    </>
  );
}

export default Uinbox;
