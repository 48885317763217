import Socevent from "../components/Soc-event";
import Unav from "../components/Unav";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
const Uevent = () => {
    return (

         <>
      <Unavbar />
      <div className="row">
        <div className="col 12 p-5 bg-dark"> </div>
      </div>
      <div className="row">
        <Unav />
            <div className="col-lg-9 col-12">
              <div className="row mb-4">

              <div className="col-12 mt-3"> 
                   <h4 className="radiant p-3 text-uppercase"> Our Society Events...</h4> 
                      </div>
            
                  <Socevent />
                </div>
              </div>
            </div>
         
        
            <Footer />
       
        </>
    );

}

export default Uevent;