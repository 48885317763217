
import React, { useState } from 'react'; 
const Select = () =>{

const [selectedOption, setSelectedOption] = useState('');
const [showTextField, setShowTextField] = useState(false);

const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);


    setShowTextField(selectedValue === 'other');
};
    return(
  <>
       
        <div className="col-lg-3 col-5 mt-2">
            <select className="form-select" id="exampleFormControlSelect1" value={selectedOption} onChange={handleSelectChange}>
                <option value="">Select...</option>
                <option value="option1">No</option>
                <option value="other">Yes</option>
            </select>
        </div>
        {showTextField && (
            <div className="col-lg-3 col-7 mt-2">

                <input type="text" className="form-control" id="exampleFormControlInput1"  placeholder='Enter Number' />
            </div>
        )}
</>
    )
}

export default Select;