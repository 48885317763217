import React from "react";



const Cduties = () =>{

    return(
        <>
<section className="hero-section" id="section_1">
  <div className="section-overlay" />
  <div className="video-wrap">
    <img src="assets/images/duties.jpg" alt="Housing Society Duties" className="custom-video" id="imgym" />
  </div>
</section>

<section className="about-section">
  <div className="container">
    <h2 className="text-danger mt-2">Duties of Residents in a Housing Society</h2>
    <hr />
  </div>
</section>

<section>
  <div className="container">
    <p className="nmn" style={{ textAlign: 'justify', fontWeight: 500 }}>
      This article discusses the obligations and duties of residents in society to evolve the fundamentals of acceptable behavior.
    </p>
    <h4 className="nmn" style={{ textAlign: 'justify', color: 'green' }}>
      Specific Points Governing<span style={{ color: '#0170B9' }}> Duties of Residents</span>
    </h4>
    <p className="nmn" style={{ textAlign: 'justify', fontWeight: 500 }}>
      <strong>Cleanliness:</strong> All residents must keep their flats and surroundings clean and usable. Higher floor residents should refrain from throwing trash out of their windows and balconies.
    </p>
    <p className="nmn" style={{ textAlign: 'justify', fontWeight: 500 }}>
      <strong>Structural changes:</strong> If residents wish to make any changes or additions to the structure, they must first apply to the Secretary of the Society, outlining the overall plan.
    </p>
    <p className="nmn" style={{ textAlign: 'justify', fontWeight: 500 }}>
      <strong>Maintenance Fees:</strong> Residents must pay their maintenance fees and any other dues set by the community on time.
    </p>
    <p className="nmn" style={{ textAlign: 'justify', fontWeight: 500 }}>
      <strong>Attendance at Meetings:</strong> Members must attend the general body’s annual and special meetings. Law mandates that they attend a minimum of one general meeting every five years.
    </p>
    <p className="nmn" style={{ textAlign: 'justify', fontWeight: 500 }}>
      <strong>Awareness of Society’s Rules:</strong> Residents should be aware of the rules of society and follow the regulations and bylaws. They should also make their tenants aware of these laws.
    </p>
    <p className="nmn" style={{ textAlign: 'justify', fontWeight: 500 }}>
      <strong style={{ color: '#0170B9' }}>Inspection of Flats:</strong> After notifying the member, the Secretary can inspect the flat or home at a mutually suitable time, to evaluate its condition and whether any repairs are required.
    </p>
    <p className="nmn" style={{ textAlign: 'justify', fontWeight: 500 }}>
      <strong>Repairs to Structural Damages:</strong> If the Secretary advises repairs based on this inspection, residents must undertake them forthwith. If they do not do so, society can carry them out after notifying the member and collecting the due amount from him.
    </p>
    <p className="nmn" style={{ textAlign: 'justify', fontWeight: 500 }}>
      <strong>Pet etiquette:</strong> Members should not keep pets such as birds, dogs, cats, reptiles, and so on without first obtaining consent from the managing committee. Society should make it clear how to keep and care for pets. This could be done through model bylaws or a resolution at any general body meeting.
    </p>
    <p className="nmn" style={{ textAlign: 'justify', fontWeight: 500 }}>
      <strong>Parking Responsibilities:</strong> The parking areas assigned by the society are the members’ rightful slots. Other than the notified parking area, do not park any vehicles haphazardly in public areas.
    </p>
    <p className="nmn" style={{ textAlign: 'justify', fontWeight: 500 }}>
      <strong>Storing Dangerous or Illegal Items:</strong> It is against the law to store anything toxic or flammable. Residents must not do anything illegal or against the law on the premises or property of the society.
    </p>
    <p className="nmn" style={{ textAlign: 'justify', fontWeight: 500 }}>
      <strong>Respecting Privacy:</strong> No one should get involved in the personal lives of their neighbors. It is not acceptable to read their mail, spread rumors, or create conditions that make situations even worse.
    </p>
    <p className="nmn" style={{ textAlign: 'justify', fontWeight: 500 }}>
      <strong>Vigilance:</strong> As a member of the society, if you think someone is acting in a way that is against the rules, such as having criminal ties, misusing their home, stealing money, or doing something else that is against the rules, you should tell the managing committee right away.
    </p>
    <p className="nmn" style={{ textAlign: 'justify', fontWeight: 500 }}>
      <strong>Using a Residence for Personal Business:</strong> Residents may choose to use their flat for commercial purposes, such as a baby nursery, yoga or meditation classes, dispensary, coaching classes, beauty parlour, or other venture, as per society by-laws.
    </p>
    <h4 style={{ color: 'var(--e-global-color-vamtam_accent_6)', textAlign: 'justify' }} className="my-3">
      General Guidelines Regarding Duties Of Residents In A Housing Society
    </h4>
    <ul className="nmn" style={{ textAlign: 'justify', listStyleType: 'square' }}>
      <li>Residents must maintain healthy relationships with each other for the community to thrive as a whole. To avoid bothering others, residents should avoid holding loud gatherings at odd hours.</li>
      <li>Residents should use the playground and clubhouses according to the schedule so that it does not disturb others.</li>
      <li>Set up a code of conduct for meetings of the general body.</li>
      <li>Disclose correct and verifiable information about themselves, their tenants, servants, and others using their premises with legitimate documents to society while registering.</li>
    </ul>
    <p className="nmn" style={{ textAlign: 'justify', fontWeight: 500 }}>
      There will always be a minority who requires explicit instructions on these commonsense rules. Dealt with them as per society’s by-laws and explain the importance of these guidelines. In a gated community, we should not only be aware of but also follow our social obligations.
    </p>
  </div>
</section>


 
        </>
    );
}
export default Cduties;