import React from "react";
import Footer from "../components/Footer";
import Unavbar from "../components/Unavbar";
import Celection from "./Celection";

const Uelection = () => {

    return(
        <>
            <Unavbar />
         <Celection />
            <Footer/>
    </>
    );
}
export default Uelection;
